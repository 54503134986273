// Adjust these paths to your skin path if you want to recompile the SASS
@import "../../../_default/Skins/root-service/src/scss/_variables.scss";
@import "../../../_default/Skins/root-service/node_modules/bootstrap/scss/mixins/_breakpoints.scss";

@media all {
  .aspect-ratio {
    position: relative;
    
    &.height-16-9 {
      padding-top: 56.25%;
    }

    &.height-4-3 {
      padding-top: 75%;
    }

    &.height-3-2 {
      padding-top: 66.66%;
    }

    &.height-8-5 {
      padding-top: 62.5%;
    }

    &.height-2-1 {
      padding-top: 50%;
    }

    &.height-1-1 {
      padding-top: 100%;
    }

    &.height-16-9,
    &.height-4-3,
    &.height-3-2,
    &.height-8-5,
    &.height-2-1,
    &.height-1-1 {
      width: 100%;

      .inner {
        position:  absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
  
        .swiper-container {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }

  [style*="--aspect-ratio"] {
    .inner {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      .swiper-container {
        width: 100% !important;
        height: 100% !important;
      } 
    }
  }
  
  .swiper-button-next,
  .swiper-button-prev {
    color: $secondary;
    text-shadow: none;

    &:after {
      font-size: 28px;
    }
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-container.standard-slider {
    width: 100%;
    height: 50vh;

    &.swiper-testimonials {
      width: 90%;
      height: auto;

      .swiper-slide {
        padding: 0 25px;
        
        @include media-breakpoint-up(md)  {
          padding: 0 40px;
        }

        p {
          font-size: 1rem;
          color: $primary;
          text-shadow: none;

          @include media-breakpoint-up(md)  {
            font-size: 1.25rem;
            line-height: 35px;
          }
        }
      }
    }

    .swiper-slide {
      .image-wrapper {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        .overlay-none {
          display: none;
        }

        &.content-effect-gradient,
        &.content-effect-gradient-wide {
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
          }
        }

        &.content-position-cc,
        &.content-position-cl,
        &.content-position-cr {
          .overlay-gradient {
            background: linear-gradient(0deg, rgba(255,255,255,0) 25%, rgba(0,0,0,0.4) 50%, rgba(255,255,255,0) 75%);
          }

          .overlay-gradient-wide {
            background: linear-gradient(0deg, rgba(255,255,255,0) 10%, rgba(0,0,0,0.4) 50%, rgba(255,255,255,0) 90%);
          }
        }

        &.content-position-bc,
        &.content-position-bl,
        &.content-position-br {
          .overlay-gradient {
            background: linear-gradient(0deg, rgba(0,0,0,0.4) 25%, rgba(255,255,255,0) 50%);
          }

          .overlay-gradient-wide {
            background: linear-gradient(0deg, rgba(0,0,0,0.4) 50%, rgba(255,255,255,0) 100%);
          }
        }

        &.content-position-tc,
        &.content-position-tl,
        &.content-position-tr {
          .overlay-gradient {
            background: linear-gradient(0deg, rgba(255,255,255,0) 50%, rgba(0,0,0,0.4) 75%);
          }

          .overlay-gradient-wide {
            background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.4) 50%);
          }
        }
      }

      p {
        color: #fff;
				font-size: $font-size-base*1.25;
        text-shadow: 0px 0px 16px rgba(0, 0, 0, 0.8);
        margin: 0 0 $font-size-base * 0.6875;
        
        &.lead { 
          margin-bottom: $grid-gutter-width/2;
          font-size: $font-size-base*1.8;	
          font-weight: 600;

          @include media-breakpoint-up(md)  {
            line-height: 1.2em;
            font-size: $font-size-base*3;	
          }		
          @include media-breakpoint-up(lg)  {
            line-height: 1.2em;
            font-size: $font-size-base*4;	
          }				
          @include media-breakpoint-up(xl)  {
            line-height: 1.2em;
            font-size: $font-size-base*5;	
          }				
        }
			}	
    }

    .swiper-button-next,
    .swiper-button-prev {
      color: #fff;
      text-shadow: 0px 0px 8px rgba(0, 0, 0, 1);
    }

    .swiper-pagination-bullet {
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    }

    .swiper-pagination-bullet-active {
      background-color: #fff;
    }
  }
  .gallery .swiper-slide {
    background-color: white;
    align-self: center;
    text-align: center;
}

.gallery-thumbs {
  height: auto;

  img {
    cursor: pointer;
    max-width: 40px;
    max-height: 40px;
    margin: 0 auto;

    &.rounded-img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .swiper-slide {
    display: flex;
    align-self: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: auto;
    min-height: 100px;
    opacity: 0.4;  
    text-align: center; 

    small {
      color: #9B9B9B;
    }

    &.swiper-slide-active {
      opacity: 1;

      img {
        max-width: 100px;
        max-height: 100px;

        &.rounded-img {
          width: 100px;
          height: 100px;
        }
      }  
    }   
  }
}

  .arrow {
    position: absolute;
    width: 120px;
    top: 20px;
    right: 50px;
    transform: rotate(-45deg);
    transform-origin: top right;
  }
  
  .line {
    margin-top: 14px;
    width: 90px;
    background: #000;
    height: 10px;
    float: left;
  }
  
  .point {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 30px solid #000;
    float: right;
  }
}